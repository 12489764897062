import React, { useState } from 'react'
import loadable from '@loadable/component'
import { Button } from '../../../components/Button'
import Ticket from '../../../components/games/ticketGenerator'
import config from '../../../../data/SiteConfig'

const Layout = loadable(() => import('../../../layout'))
const GameSwitchButton = loadable(() => import('../../../components/games/RandomBlocks'))
const TambolaTicketComponent = loadable(() => import('../../../components/games/TambolaTicket'))

const seoDescription =
  'Generate paperless Tambola tickets, Ad less Tambola / Housie / Indian Bingo Online Game, Create tambola tickets'

const TicketGenerator = ({ location }) => {
  const [name, setName] = useState('')
  const [isTicketGenertated, setTicketGenerationStatus] = useState(false)
  const [ticketCollection, setTicketCollection] = useState(null)

  const generateTicket = () => {
    const ticket = new Ticket()
    ticket.generate()
    setTicketGenerationStatus(true)
    setTicketCollection(ticket)
    return ticket
  }

  return (
    <Layout
      title={`Create paperless Tambola / Housie tickets  – ${config.siteTitle}`}
      seoDescription={seoDescription}
      seoImage={config.tambolaCover}
      href={location.href}
    >
      <div className="container text-center">
        <h1 className="text-center">Generate Tambola Ticket</h1>
      </div>
      {isTicketGenertated ? (
        <div className="container text-center">
          <h2 className="text-center">
            Welcome <span>{name}</span>, Here&apos;s your ticket
          </h2>
          <section className="section-ticket flex-center">
            <TambolaTicketComponent ticketSet={ticketCollection} playerName={name} />
          </section>
          <Button className="submit-button margin-0" onClick={generateTicket}>
            Generate a New Ticket
          </Button>
        </div>
      ) : (
        <div className="container text-center">
          <label aria-label="tambola ticket name" htmlFor="tambola-ticket-name">
            Enter your Name & Generate Ticket
          </label>
          <section className="name-section flex">
            <input
              id="tambola-ticket-name"
              className="name-field"
              type="text"
              name="name"
              aria-label="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength="25"
              placeholder="Enter your name here..."
            />
            <Button className="submit-button margin-0" onClick={generateTicket}>
              Generate Ticket
            </Button>
          </section>
          <GameSwitchButton path="/games/tambola" buttonTitle="Become A Host" />
        </div>
      )}
    </Layout>
  )
}
export default TicketGenerator
